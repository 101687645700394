import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { DeletingAbstract } from '../abstract/delete-abstract'

export class DeleteUserViewModel extends DeletingAbstract {
  @observable userId = ''
  @observable loading = false;

  @asyncAction *deleteDependencies() {
    try {
      this.loading = true
      const res = yield apiService.apiHandler.adminDeleteUser(this.userId)
      if (res) {
        snackController.success('User posts and dependencies have deleted')
        this.userId = ''
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }
}
