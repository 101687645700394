


















import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { DeleteUserViewModel } from '../viewmodels/delete-user-viewmodel'

@Observer
@Component
export default class DeletePost extends Vue {
  @Provide() vm = new DeleteUserViewModel()
}
